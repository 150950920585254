import React from 'react'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import styled from 'styled-components'
import './styles.scss'
import { useSelector } from 'react-redux'
import { IconComponent } from '@/components/common/IconComponent'
import { MycButton } from '@/components/common/Button'
import selectors from '@/redux/selectors'
import { ICONS } from '@/utils/icons'

const ContentContainer = styled.div`
  color: #000;
  background-color: rgba(255, 255, 255, 0.9);
  height: 285px;
  text-align: left;
  padding: 25px;
`

const Header = styled.div`
  font-size: 22px;
  margin-bottom: 15px;
`
const Submit = styled.div`
  width: 200px;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  background: #6d9f43;
  margin: 10px 0;
  float: right;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  cursor: pointer;
`
const Note = styled.div`
  margin-top: 20px;
`
const UserInputWrapper = styled.div``
const UserIconWrapper = styled.div``
const UserInput = styled.div``

const StyledUserIcon = styled(IconComponent)`
  font-size: 20px;
  margin: 0 auto;
  margin-top: 14px;
  margin-right: 2px;
`

const UserIcon = () => {
  return <StyledUserIcon icon={ICONS.user} />
}

const StyledQuestionIcon = styled(IconComponent)`
  font-size: 20px;
  margin: 0 auto;
  margin-top: 14px;
  margin-right: 2px;
`

const QuestionIcon = () => {
  return <StyledQuestionIcon icon={ICONS.questionCircle} />
}

const RenderContent = () => {
  const userName = useSelector((state) => selectors.auth.getUsername(state))
  return (
    <>
      <ContentContainer>
        <form action="https://chat.saws.org/ccp/chat/form/100000" method="post" target="_blank">
          <input type="hidden" name="author" value="Customer" />
          <input type="hidden" name="title" value="ccx chat" />
          <input type="hidden" name="extensionField_h_widgetName" value="Public Chat" />
          <input type="hidden" name="extensionField_contextServiceCustomFieldSets" value="" />
          <input
            type="hidden"
            name="extensionField_chatLogo"
            value="https://www.saws.org/wp-content/themes/saws-theme/dist/images/saws-logo_327c0046.svg"
          />
          <input
            type="hidden"
            name="extensionField_chatWaiting"
            value="Thank you for contacting San Antonio Water System via chat. A customer service agent will be with you shortly."
          />
          <input
            type="hidden"
            name="extensionField_chatAgentJoinTimeOut"
            value="All of our agents are busy helping other customers. If you would like to send an email inquiry instead, please visit http://www.saws.org/service/cs_assistance/index.cfm . Otherwise, please try the chat feature again later. Thank you."
          />
          <input
            type="hidden"
            name="extensionField_chatError"
            value="Our chat feature is currently unavailable. Chat hours of operation are 8 a.m. to 5 p.m. Monday through Friday."
          />

          <Header>How may we help you today?</Header>
          <UserInputWrapper className="inputWrapper">
            <UserIconWrapper className="iconWrapper">
              <UserIcon />
            </UserIconWrapper>
            <UserInput className="inputContainer">
              <input
                type="text"
                value={userName}
                name="extensionField_Name"
                className="inputField"
              />
            </UserInput>
          </UserInputWrapper>
          <UserInputWrapper className="inputWrapper">
            <UserIconWrapper className="iconWrapper">
              <QuestionIcon />
            </UserIconWrapper>
            <UserInput className="inputContainer">
              <select className="inputField" name="extensionField_ccxqueuetag">
                <option selected value="Chat_Csq63">
                  General Questions
                </option>
                <option value="Chat_Csq64">Compliments or Complaints</option>
              </select>
            </UserInput>
          </UserInputWrapper>
          <MycButton
            gutterBottom={1}
            style={{
              padding: '10px 0',
              color: '#fff',
              width: '216px',
              height: '44px',
              marginTop: '10px',
              cursor: 'pointer',
              background: '#6d9f43',
            }}
            type="submit"
          >
            Begin Live Chat Now
          </MycButton>
        </form>
      </ContentContainer>
    </>
  )
}

const DropdownChat = ({ open }) => {
  return (
    <SlideDown className="my-dropdown-slidedown" closed={!open}>
      {open && <RenderContent />}
    </SlideDown>
  )
}

export default DropdownChat
