import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { OBJECT_VARS } from '@/utils/models'
import { TabStrip, TabStripTab, Card } from '@progress/kendo-react-layout'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { formatDate, parseDollars } from '@/utils/utils'
import { getBillSummary } from '@/redux/api'
import { useConsumption } from '@/hooks/useConsumption.queries'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { usePayments } from '@/hooks/usePayments.queries'
import { motion } from 'framer-motion'
import 'hammerjs'
import { Typography } from '@progress/kendo-react-common'
import { Button } from '@progress/kendo-react-buttons'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { BillHighlightsComponent } from './BillHighlightsComponent'
import { PayBillButton } from './PayBillButton'
import { ViewBillComponent } from './ViewBillComponent'
import { useCancelScheduledPayment } from './useCancelScheduledPayment.queries'
import { usePaymentHistory } from './usePaymentHistory.queries'
import { PaymentHistoryComponent } from './PaymentHistoryComponent'
import { BillHistoryComponent } from './BillHistoryComponent'
import EBillComponent from './EBillComponent'
import { useLastPayment } from '../../../hooks/useLastPayment.queries'

const AccountTitle = ({ nickName, address }) => {
  return (
    <>
      <p style={{ marginBottom: 0, lineHeight: '35px' }}>{nickName}</p>
      <p style={{ marginBottom: 0, fontSize: '20px' }}>{address}</p>
    </>
  )
}

const container = {
  show: {
    transition: {
      staggerChildren: 0.3,
    },
  },
}
const item = {
  hidden: { opacity: 0, y: 50 },
  show: { opacity: 1, y: 0 },
}

const SimpleBarChart = ({ data }) => {
  return (
    <BarChart
      width={600}
      height={300}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      style={{ width: `100%` }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Gallons" fill="#0078ae" />
    </BarChart>
  )
}

const History = () => {
  const { key } = useParams()
  const accountKey = Number(key)
  const cancelScheduledPaymentQuery = useCancelScheduledPayment(accountKey)
  const consumptionQuery = useConsumption(accountKey)
  const usageData = consumptionQuery.data
  const [noUsageRecords, setNoUsageRecords] = React.useState(null)

  const getUsageDataForAccount = () => {
    if (!usageData) return undefined
    if (usageData.records.length === 0) {
      return [{ Date: dayjs(), Gallons: 0 }]
    }
    return usageData.records.map((item) => {
      const date = formatDate(item[OBJECT_VARS.readDate])
      return { Date: date, Gallons: item[OBJECT_VARS.usageGallons] }
    })
  }

  const accountQuery = useAccounts({
    select: (res) => res.find((el) => el[OBJECT_VARS.accountKey] === accountKey),
  })
  const account = accountQuery.data
  const paymentQuery = usePayments(accountKey, {
    select: (res) => res.find((el) => el[OBJECT_VARS.accountKey] === accountKey),
  })
  const payment = paymentQuery.isSuccess && paymentQuery.data

  const address =
    accountQuery.isSuccess &&
    account &&
    `${account[OBJECT_VARS.serviceAddress]} ${account[OBJECT_VARS.subDesignation]}`

  const lastPaymentQuery = useLastPayment(accountKey)
  const [lastBillKey, SetLastBillKey] = useState(null)

  useEffect(() => {
    if (accountKey === null) return undefined
    getBillSummary(accountKey)
      .then((res) => {
        SetLastBillKey(res.billkey)
      })
      .catch((e) => {
        console.warn(e)
        SetLastBillKey(0)
      })
  }, [accountKey])

  const scheduledPaymentQuery = usePaymentHistory(accountKey, {
    select: (res) => res.find((el) => el.status === 'SCHEDULED'),
  })
  const scheduledPayment = scheduledPaymentQuery.data

  React.useEffect(() => {
    if (!usageData) return undefined
    if (usageData.records.length === 0) {
      return setNoUsageRecords(true)
    }
    return setNoUsageRecords(false)
  }, [usageData])

  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected)
  }

  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <Typography.h4>
            <AccountTitle
              nickName={accountQuery.isSuccess && account[OBJECT_VARS.nickName]}
              address={accountQuery.isSuccess && address}
            />
          </Typography.h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <motion.div initial="hidden" animate="show" variants={container}>
            <Card className="text-center p-4">
              <p>Account #: {accountQuery.isSuccess && account[OBJECT_VARS.accountNumber]}</p>
              <div>
                <Typography.h5 className="text-black">Balance Due</Typography.h5>
                <p className="line-black" />
                <Typography.h3 className="text-blue-col">
                  {parseDollars(payment[OBJECT_VARS.finalBalance])}
                </Typography.h3>
                <Typography.p className="text-orange-col font-bold">
                  Current Charges Due:
                  {formatDate(payment[OBJECT_VARS.dueDate])}
                </Typography.p>

                {lastPaymentQuery.isLoading ? (
                  <Skeleton width="200px" />
                ) : (
                  <>
                    {lastPaymentQuery.data === null ? (
                      ''
                    ) : (
                      <Typography.p>
                        Last Payment:&nbsp;
                        <b>{parseDollars(lastPaymentQuery.data?.amount)}</b> paid on&nbsp;
                        {formatDate(lastPaymentQuery.data?.date)}
                      </Typography.p>
                    )}
                  </>
                )}
                <PayBillButton accountKey={accountKey}>Pay This Bill</PayBillButton>

                {scheduledPayment !== null && scheduledPayment !== undefined ? (
                  <>
                    <Typography.p className="mt-3">
                      This Account has a Scheduled Payment for{' '}
                      <b>{parseDollars(scheduledPayment.amount)}</b> on{' '}
                      {formatDate(scheduledPayment.date)}
                    </Typography.p>
                    <Button
                      onClick={() => cancelScheduledPaymentQuery.mutate(scheduledPayment.id)}
                      className="btn-myc-orange"
                    >
                      {cancelScheduledPaymentQuery.isLoading
                        ? 'Canceling'
                        : 'Cancel Scheduled Payment'}
                    </Button>
                  </>
                ) : (
                  ''
                )}

                <BillHighlightsComponent accountKey={accountKey} billKey={lastBillKey} />
                <ViewBillComponent accountKey={accountKey} billKey={lastBillKey}>
                  View Current Bill
                </ViewBillComponent>
                <EBillComponent accountKey={accountKey} />
              </div>
            </Card>
          </motion.div>
        </div>
        <div className="col-lg-8">
          <motion.div initial="hidden" animate="show" variants={container}>
            <motion.div variants={item}>
              <Typography.h5 className="text-black">Monthly Usage</Typography.h5>
              <hr />
              <div className="">{noUsageRecords && 'No Records'}</div>
              <SimpleBarChart data={getUsageDataForAccount()} />
            </motion.div>
            <motion.div variants={item}>
              <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                <TabStripTab title="Bill History">
                  <BillHistoryComponent accountKey={accountKey} />
                </TabStripTab>
                <TabStripTab title="Payment History">
                  <PaymentHistoryComponent accountKey={accountKey} />
                </TabStripTab>
              </TabStrip>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  )
}

export { History }
