import React, { useEffect, useState } from 'react'
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid'
import Skeleton from 'react-loading-skeleton'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Button } from '@progress/kendo-react-buttons'
import moment from 'moment'
import { OBJECT_VARS } from '@/utils/models'
import { getBillCollection } from '@/redux/api'
import { process } from '@progress/kendo-data-query'
import { ViewBillComponent } from './ViewBillComponent'

const initialDataState = {
  sort: [],
  take: 10,
  skip: 0,
}

const BillHistoryComponent = ({ accountKey }) => {
  const _export = React.useRef(null)
  const [dataState, setDataState] = React.useState(initialDataState)

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save()
    }
  }

  const ViewBillButton = (props) => {
    return (
      <td>
        <ViewBillComponent accountKey={accountKey} billKey={props.dataItem.billNo}>
          View
        </ViewBillComponent>
      </td>
    )
  }

  const FormatBillDate = (props) => {
    return <td>{moment(props.dataItem[OBJECT_VARS.billDate]).format('MMMM Do, YYYY')}</td>
  }

  const [data, SetData] = useState(null)

  useEffect(() => {
    if (data === null) {
      getBillCollection(accountKey)
        .then((res) => {
          SetData(res)
        })
        .catch((e) => {
          SetData([])
          console.warn(e)
        })
    }
  }, [accountKey, data])

  return data === null ? (
    <div style={{ height: '200px', width: '600px' }}>
      <Skeleton count={10} />
    </div>
  ) : (
    <ExcelExport data={data} ref={_export}>
      <Grid
        pageable
        sortable
        data={process(data, dataState)}
        {...dataState}
        onDataStateChange={(e) => {
          setDataState(e.dataState)
        }}
      >
        <GridToolbar>
          <Button
            title="Export Excel"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={excelExport}
          >
            Export to Excel
          </Button>
        </GridToolbar>
        <GridColumn field="billDate" title="Date" cell={FormatBillDate} />
        <GridColumn field="billAmount" title="Amount" format="{0:c2}" />
        <GridColumn field="billNo" title="View Bill" cell={ViewBillButton} />
      </Grid>
    </ExcelExport>
  )
}

export { BillHistoryComponent }
