import React from 'react'
import styled from 'styled-components'
import { OBJECT_VARS } from '@/utils/models'
import { Label, Input } from '@/components/common/Input'

const Title = styled.h1`
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  margin-top: 30px;
  padding-top: 11px;
  line-height: 41px;
  color: #000000;
  text-align: center;
`

const FormInputContainer = styled.div`
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 181px;
  border-radius: 8px;
`

const Required = styled.div`
  color: #da690b;
  margin: -4px 2px 5px 8px !important;
  position: relative;
`

const FormField = ({ label, variant, ...rest }) => {
  return (
    <div>
      <span style={{ display: 'flex' }}>
        <Label label={label} variant={variant} />
        {label !== 'Job Title' && label !== 'mobile number' ? (
          <Required>* required</Required>
        ) : null}
      </span>
      <Input bottomGutter={1} {...rest} required />
    </div>
  )
}
const FormFields = ({ data, onChange, accountKey }) => {
  return data
    ? data.map((field) => (
        <div>
          <FormField
            key={field.name}
            label={field.label}
            name={field.name}
            value={field.value || ''}
            onChange={(e) => onChange(accountKey, field.name, e.target.value)}
            variant="light"
            // type= {field.name == 'mobileNumber' && field.value == "" ? "number" : 'text'}
          />
          {field.label !== 'Job Title' && field.label !== 'mobile number' ? (
            field.value == '' ? (
              <p style={{ color: '#ff0000' }}>Please Enter {field.label}</p>
            ) : null
          ) : null}
        </div>
      ))
    : null
}
const MoveOutFormComponent = ({ getAddresses, accountKey, isVisible }) => {
  const { addresses, updateAddressField } = getAddresses

  const getFormData = () =>
    addresses &&
    addresses[accountKey] && [
      {
        name: 'name',
        label: 'name',
        value: addresses[accountKey].name,
        required: true,
      },
      {
        name: OBJECT_VARS.mobile,
        label: 'mobile number',
        value: addresses[accountKey][OBJECT_VARS.mobile],
        required: true,
      },
      {
        name: OBJECT_VARS.taxId,
        label: 'tax id',
        value: addresses[accountKey][OBJECT_VARS.taxId],
        required: true,
      },
      {
        name: OBJECT_VARS.jobTitle,
        label: 'Job Title',
        value: addresses[accountKey][OBJECT_VARS.jobTitle],
        required: true,
      },
      {
        name: [OBJECT_VARS.transferringToAddress],
        label: 'mailing address',
        value: addresses[accountKey][OBJECT_VARS.transferringToAddress],
        required: true,
      },
      {
        name: [OBJECT_VARS.transferringToCity],
        label: 'city',
        value: addresses[accountKey][OBJECT_VARS.transferringToCity],
        required: true,
      },
      {
        name: [OBJECT_VARS.transferringToState],
        label: 'state',
        value: addresses[accountKey][OBJECT_VARS.transferringToState],
        required: true,
      },
      {
        name: [OBJECT_VARS.transferringToZipCode],
        label: 'zip code',
        value: addresses[accountKey][OBJECT_VARS.transferringToZipCode],
        required: true,
      },
      {
        name: [OBJECT_VARS.transferringToCountry],
        label: 'country',
        value: addresses[accountKey][OBJECT_VARS.transferringToCountry] || 'USA',
        required: true,
      },
    ]

  return (
    <div style={{ display: isVisible ? 'block' : 'none' }}>
      <Title>Send the Final Bill for This Account to:</Title>
      <FormInputContainer>
        <form>
          <FormFields accountKey={accountKey} data={getFormData()} onChange={updateAddressField} />
        </form>
      </FormInputContainer>
    </div>
  )
}

export { MoveOutFormComponent }
