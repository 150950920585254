import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import selectors from '@/redux/selectors'
import { OBJECT_VARS } from '@/utils/models'
import { useSelector } from 'react-redux'
import { formatDate, parseDollars } from '@/utils/utils'
import { IconComponent } from '@/components/common/IconComponent'
import { GridCardInfoButton } from '@/components/common/GridCardInfoButton'
import { GridCardPaymentButton } from '@/components/common/GridCardPaymentButton'
import { GridCardOverlayComponent } from '@/components/common/GridCardOverlayComponent'
import { usePayments } from '@/hooks/usePayments.queries'
import { useLastPayment } from '../../../hooks/useLastPayment.queries'

// ----------------- Styled Components ------------------ //

const InfoIconContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const AccountIcon = styled(IconComponent)`
  margin-right: 0;
  color: ${({ theme }) => theme.info};
  margin-bottom: 10px;
`

const DateText = styled.span`
  display: block;
  font-size: 1.3em;
  font-weight: 200;
  margin-top: 0;
`

const PaymentScheduledText = styled.span`
  display: block;
  font-size: 11px;
  color: #f5a200;
`

const SubTitle = styled.span`
  display: block;
  margin: 2px 0;
  font-weight: 800;
  font-size: 16px;
  height: 16px;
`

const Total = styled.h4`
  color: #3250b3;
  font-size: 20px;
  margin: 2px 0;
  font-weight: 500;
  line-height: 35px;
`

const StyledImg = styled.img`
  width: 1.25em;
  font-size: 1.7em;
  margin: 14px;
`

// ----------------------------------------------------------- //

const GridCard = ({ account, selectAccount }) => {
  const paymentsQuery = usePayments([account.accountKey])
  const payments = paymentsQuery.data && paymentsQuery.data[0]
  const accountKey = account[OBJECT_VARS.accountKey]
  const billKey = account[OBJECT_VARS.billKey]
  const finalBalance = payments && parseDollars(payments[OBJECT_VARS.finalBalance])
  const accountNumber = account[OBJECT_VARS.accountNumber]
  const dueDate = payments && formatDate(payments[OBJECT_VARS.dueDate])
  const scheduledPaymentAmount = payments && payments[OBJECT_VARS.scheduledPayment]
  const paymentScheduledDate = payments && formatDate(payments[OBJECT_VARS.paymentScheduledDate])
  const accountStatus = payments && payments[OBJECT_VARS.accountStatus]
  const serviceAddress = account[OBJECT_VARS.serviceAddress]
  const subDesignation = account[OBJECT_VARS.subDesignation]
  const nickname = account[OBJECT_VARS.nickName]
  const accountIcon = selectors.icon.getIconFromList(account.icon)
  const accountIsSelected = useSelector((state) =>
    selectors.account.getIsAccountSelected(state, accountKey)
  )
  const showScheduled = scheduledPaymentAmount && scheduledPaymentAmount >= 0
  const paymentsUnavailable = accountStatus === undefined
  const handleSelectAccount = () => {
    if (!paymentsUnavailable && scheduledPaymentAmount <= 0) {
      selectAccount(accountKey)
    }
  }

  const lastPaymentQuery = useLastPayment(account.accountKey)

  return (
    <>
      <div
        role="button"
        tabIndex="0"
        className="card card-shadow"
        style={{ width: '320px', height: '214px' }}
        onKeyPress={handleSelectAccount}
        onClick={handleSelectAccount}
      >
        {accountIsSelected ? <GridCardOverlayComponent /> : null}
        <div className="card-body" style={{ padding: '0rem' }}>
          <div className="row mt-2">
            <div className="col-12" style={{ fontWeight: '600', 'font-size': '0.9rem' }}>
              Account# {accountNumber}
            </div>
          </div>
          <div className="row">
            <div className="col-9">
              <button
                style={{
                  display: 'inline',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: '0px',
                  'font-weight': '400',
                  'font-size': '1rem',
                  width: '230px',
                  fontWeight: 'bolder',
                }}
                type="button"
                className="btn btn-default"
                data-toggle="tooltip"
                data-placement="left"
                title={nickname}
              >
                {nickname}
              </button>

              <Total
                accountStatus={accountStatus}
                showScheduled={showScheduled}
                style={{
                  color:
                    accountStatus === 'Past Due' || accountStatus === 'Delinquent'
                      ? 'red'
                      : '#3250b3',
                }}
              >
                {paymentsUnavailable ? <Skeleton count={1} width={150} /> : `${finalBalance} Due`}
              </Total>
              <DateText>
                {paymentsUnavailable ? <Skeleton count={1} width={200} /> : `Due Date: ${dueDate}`}
              </DateText>
              {/* {new Date(dueDate).getTime() < new Date().getTime() &&
              accountStatus == 'Delinquent' ? (
                <span style={{ color: '#ff0000' }}>DISCONNECTED</span>
              ) : (
                <br />
              )} */}
              {paymentsUnavailable ? (
                <td>
                  <Skeleton count={1} />
                </td>
              ) : (
                <td
                  style={
                    accountStatus === 'Delinquent' || accountStatus === 'Past Due'
                      ? { color: 'red' }
                      : { color: 'green' }
                  }
                >
                  {accountStatus}
                </td>
              )}

              <PaymentScheduledText>
                {paymentScheduledDate ? `PAYMENT SCHEDULED - ${paymentScheduledDate}` : <br />}
              </PaymentScheduledText>
              <span style={{ display: 'block', 'font-weight': '400', 'font-size': '0.8rem' }}>
                {lastPaymentQuery.isLoading ? (
                  <Skeleton width={225} />
                ) : (
                  `Last payment: ${parseDollars(lastPaymentQuery.data?.amount)}`
                )}
              </span>
              <span style={{ 'font-weight': '400', 'font-size': '0.8rem' }}>
                {/* {serviceAddress} {subDesignation} */}
                <button
                  style={{
                    display: 'inline',
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding: '0px',
                    'font-weight': '400',
                    'font-size': '0.8rem',
                    width: '230px',
                  }}
                  type="button"
                  className="btn btn-default"
                  data-toggle="tooltip"
                  data-placement="left"
                  title={serviceAddress + subDesignation}
                >
                  {serviceAddress}
                  {subDesignation}
                </button>
              </span>
            </div>
            <div className="col-3">
              <InfoIconContainer>
                {paymentsUnavailable ? (
                  <>
                    <Skeleton width={40} height={70} />
                    <Skeleton width={40} height={38} />
                  </>
                ) : (
                  <>
                    <div>
                      {accountStatus === 'Delinquent'
                        ? '' // TODO: removed the tooltip because the delinquency status is not checked properly.
                        : // <Tooltip anchorElement="target" position="top" width="400px">
                          //   <StyledImg
                          //     title="This account has been disconnected. Please contact SAWS at 210-704-7297 to discuss fees, and to restore services,
                          //      which might take up to two business days to complete."
                          //     alt="brokenpipe Logo"
                          //     src={watercutoff}
                          //   />
                          // </Tooltip>
                          ''}
                    </div>

                    <AccountIcon
                      size="2em"
                      icon={accountIcon}
                      accountStatus={accountStatus}
                      showScheduled={showScheduled}
                    />
                    <GridCardInfoButton
                      accountStatus={accountStatus}
                      accountKey={accountKey}
                      showScheduled={showScheduled}
                      dueDate={dueDate}
                    />
                    <GridCardPaymentButton
                      accountStatus={accountStatus}
                      accountKey={accountKey}
                      billKey={billKey}
                      showScheduled={showScheduled}
                      dueDate={dueDate}
                    />
                  </>
                )}
              </InfoIconContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

GridCard.propTypes = {
  selectAccount: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number,
    account_name: PropTypes.string,
    account_type: PropTypes.string,
    accountstatus: PropTypes.string,
    amount_due: PropTypes.string,
    due_date: PropTypes.string,
    address: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
}

export { GridCard }
