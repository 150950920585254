import React from 'react'
import styled from 'styled-components'
import { MycButton } from '@/components/common/Button'
import { PropTypes } from 'prop-types'

const Wrapper = styled.div`
  bottom: 107px;
  position: absolute;
  left: 32%;
  background: #fff;
  padding: 20px;
  display: block;
  border: 1px solid #3250b3;
  z-index: 10;
  width: 430px;
`

const NavButton = styled(MycButton)`
  font-weight: 100;
  font-family: Roboto;
  width: 384px;
  margin: 0 auto;
  padding: 12px;
`

export const MoveOutNavButton = ({ variant, onClick, disabled, buttonText, children, ...rest }) => {
  return (
    <Wrapper style={{ left: buttonText == 'submit' ? '21%' : '32%' }}>
      <NavButton variant={variant} onClick={onClick} disabled={disabled} {...rest}>
        {children}
      </NavButton>
    </Wrapper>
  )
}

// MoveOutNavButton.defaultProps = {
//   variant: '',
// }
MoveOutNavButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}
