import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CurrencyInput from 'react-currency-input-field'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { usePaymentApi } from '@/redux/reducers'

const AmountInput = styled(CurrencyInput)`
  width: 75px;
  background: transparent;
  font-weight: 100;
  text-align: left;
  border: none;
  cursor: pointer;
  border-bottom: ${({ theme }) => `2px dashed ${theme.primary}`};
  outline: none;
  margin-left: 2px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Message = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  color: ${({ error, theme }) => (error ? theme.warningDark : theme.success)};
`

const AccountTableEditAmountComponent = ({ account }) => {
  const dispatch = useDispatch()
  const { accountKey, amount } = account
  const fieldName = accountKey.toString()
  const ref = React.useRef()
  const { errors, control } = useFormContext()
  const handleOnBlur = (value, onBlur) => {
    dispatch(usePaymentApi.saveAmountToBePaid({ key: accountKey, amount: parseFloat(value) }))
    return onBlur(value)
  }

  return (
    <>
      $
      <Controller
        render={({ onChange, onBlur, value, name, defaultValue }) => (
          <AmountInput
            name={name}
            onValueChange={onChange}
            defaultValue={defaultValue}
            value={value}
            placeholder="Please enter a number"
            step={1}
            onBlur={() => handleOnBlur(value, onBlur)}
            decimalScale={2}
            prefix=""
          />
        )}
        name={fieldName}
        control={control}
        defaultValue={amount < 0 ? 0 : amount}
        placeholder="Type here..."
        onFocus={() => ref.current?.focus()}
        rules={{
          required: { value: true, message: 'This field is required' },
          min: { value: 0.01, message: 'Must be greater than 0.00' },
          max: { value: 999999.99, message: 'Amount cannot be greater than 999999.99' },
        }}
        isInvalid={errors.fieldName && true}
      />
      {errors[fieldName] && <Message error>{errors[fieldName]?.message}</Message>}
    </>
  )
}

AccountTableEditAmountComponent.propTypes = {
  account: PropTypes.shape({}).isRequired,
}

export { AccountTableEditAmountComponent }
