import React from 'react'
import { Animated } from 'react-animated-css'
import FilterResults from 'react-filter-search'
import { OBJECT_VARS } from '@/utils/models'
import { UsageGridCardComponent } from '@/components/common/UsageGridCardComponent'
import { SearchBarComponent } from '@/components/common/SearchBarComponent'
import { Spinner } from 'react-bootstrap'
import { useAccounts } from '../../../hooks/useAccounts.queries'

const GridView = () => {
  const accountsQuery = useAccounts()
  const accounts = accountsQuery.data
  const [searchValue, setSearchValue] = React.useState('')

  return (
    <>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="100" isVisible>
        <SearchBarComponent value={searchValue} setValue={setSearchValue} />
      </Animated>
      {accountsQuery.isLoading ? (
        <Spinner animation="grow" size="sm" />
      ) : (
        <div className="grid-container mt-3" style={{ height: 'calc(100vh - (300px))' }}>
          <FilterResults
            value={searchValue}
            data={accounts}
            renderResults={(results) =>
              results.map((account) => (
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  animationInDelay="1000"
                  isVisible
                >
                  <UsageGridCardComponent accountKey={account[OBJECT_VARS.accountKey]} />
                </Animated>
              ))
            }
          />
        </div>
      )}
    </>
  )
}

export { GridView }
