import { useQuery, useQueries } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { useGetAccountKeys } from './useAccounts.queries'

const GET_CONSUMPTION_DATA = `${process.env.REACT_APP_API_BASE_URL}/consumption-public-api/public/api/gallonsanalysis/`

const fetchConsumptionData = (accountKey) => {
  const url = GET_CONSUMPTION_DATA + accountKey
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: true,
  })
}

const useConsumption = (accountKey, options) => {
  return useQuery(['consumption', accountKey], () => fetchConsumptionData(accountKey), {
    staleTime: 1000 * 60 * 20,
    ...options,
  })
}

const useConsumptions = () => {
  const accountKeys = useGetAccountKeys() || []

  return useQueries(
    accountKeys.map((key) => {
      return {
        queryKey: ['consumption', key],
        queryFn: () => fetchConsumptionData(key),
        staleTime: 1000 * 60 * 20,
      }
    })
  )
}

export { useConsumption, useConsumptions }
