import React from 'react'
import { AreaChart, Area, XAxis, Tooltip, Label, ResponsiveContainer } from 'recharts'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { PropTypes } from 'prop-types'
import selectors from '@/redux/selectors'
import { IconComponent } from '@/components/common/IconComponent'
import { OBJECT_VARS } from '@/utils/models'
import { GridCardInfoButton } from '@/components/common/GridCardInfoButton'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { useConsumption } from '@/hooks/useConsumption.queries'
import { usePayments } from '@/hooks/usePayments.queries'
import { Skeleton } from '@progress/kendo-react-indicators'
import 'hammerjs'

const InfoIconContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`

const AccountIcon = styled(IconComponent)`
  margin-right: 0;
  color: ${({ theme }) => theme.info};
  margin-bottom: 10px;
  height: inherit;
`

const Total = styled.p`
  color: #3250b3;
  font-size: 20px;
  margin: 2px 0;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
`

const SubTitle = styled.span`
  display: block;
  margin: 2px 0;
  font-weight: 800;
  font-size: 16px;
  height: 16px;
`

const TotalDisplay = ({ total }) => {
  return <span>{total}</span>
}

const TinyAreaChart = (props) => {
  const { records } = props

  const transformedRecords = records.map((record) => ({
    ...record,
    [OBJECT_VARS.readDate]: dayjs(record[OBJECT_VARS.readDate]).startOf('d').format('MM/DD/YYYY'),
  }))

  return (
    <ResponsiveContainer width="100%" height={100}>
      <AreaChart data={transformedRecords} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <XAxis name="date" dataKey={OBJECT_VARS.readDate} tick={false}>
          <Label value="Date" position="bottom" />
        </XAxis>
        <Tooltip formatter={(value) => value.toLocaleString()} />
        <Area
          name="Gallons"
          type="monotone"
          dataKey={OBJECT_VARS.usageGallons}
          stroke="#8884d8"
          fill="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

// GRAPH
const GraphContainer = styled.div`
  width: 100%;
  height: 100%;
`

const UsageGridCardComponent = ({ accountKey }) => {
  const accountsQuery = useAccounts()
  const allaccounts = accountsQuery.isSuccess ? accountsQuery.data : []
  const [accountnumberdispaly, setaccountnumber] = React.useState()
  const [accountnickname, setaccountnickname] = React.useState('')
  const [accountKeyvalue, setaccountKeyvalue] = React.useState('')
  const [accountIconvalue, setaccountIcon] = React.useState(null)
  React.useEffect(() => {
    const selectedaccountkey = allaccounts.filter((obj) => accountKey == obj.accountKey)
    const accountkey = selectedaccountkey.length > 0 ? selectedaccountkey[0].accountKey : {}
    const accountdetailsobj = selectedaccountkey[0]
    setaccountKeyvalue(accountkey.toString())
    if (selectedaccountkey.length > 0) {
      setaccountnumber(accountdetailsobj.accountNumber)
      setaccountnickname(accountdetailsobj.nickName)
      setaccountIcon(accountdetailsobj.icon)
    }
  }, [accountsQuery.status])

  const consumptionQuery = useConsumption(accountKey)
  const paymentsQuery = usePayments([accountKey])
  const consumption = consumptionQuery.data
  const paymentobj = paymentsQuery.data

  let records
  let readDate
  let lastBillMonth
  if (consumption) {
    records = consumption ? consumption.records : []
    readDate = records.length > 0 ? records[records.length - 1][OBJECT_VARS.readDate] : null
    lastBillMonth = readDate ? dayjs(readDate).format('MMMM') : null
  }
  const accountIcon =
    accountIconvalue !== null ? selectors?.icon.getIconFromList(accountIconvalue) : null
  const accountStatus = paymentobj ? paymentobj[0].accountStatus : false
  const accountSechduled = paymentobj
    ? paymentobj[0].scheduledPayment && paymentobj[0].scheduledPayment >= 0
    : false
  return (
    <>
      <div className="card card-shadow" style={{ width: '320px', height: '202px' }}>
        <div className="card-body" style={{ padding: '0rem' }}>
          <div className="row mt-2">
            <div className="col-12" style={{ 'font-weight': '600', 'font-size': '0.9rem' }}>
              Account # {accountnumberdispaly}
            </div>
          </div>
          <div className="row">
            <div className="col-9">
              <button
                style={{
                  display: 'inline',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: '0px',
                  'font-weight': '400',
                  'font-size': '1rem',
                  width: '230px',
                  fontWeight: 'bolder',
                }}
                type="button"
                className="btn btn-default"
                data-toggle="tooltip"
                data-placement="left"
                title={accountnickname}
              >
                {accountnickname}
              </button>

              <Total>
                {!consumption ? (
                  <Skeleton />
                ) : (
                  <TotalDisplay
                    total={
                      records.length > 0
                        ? records[records.length - 1][OBJECT_VARS.usageGallons].toLocaleString()
                        : 0
                    }
                  />
                )}
              </Total>
              <p style={{ fontSize: '14px', textAlign: 'left' }}>
                {lastBillMonth !== null ? `gallons billed for ${lastBillMonth || ''}` : 'No data'}
              </p>
            </div>
            <div className="col-3">
              <InfoIconContainer>
                <div style={{ height: '30px', marginBottom: '10px' }}>
                  <AccountIcon size="2em" icon={accountIcon} />
                </div>
                <GridCardInfoButton
                  accountKey={parseInt(accountKeyvalue)}
                  showScheduled={accountSechduled}
                />
              </InfoIconContainer>
            </div>
          </div>

          <div className="row">
            <GraphContainer>
              {!consumption ? null : (
                <TinyAreaChart hasNickname={!!accountnickname} records={consumption.records} />
              )}
            </GraphContainer>
          </div>
        </div>
      </div>
    </>
  )
}

UsageGridCardComponent.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    account_name: PropTypes.string,
    account_type: PropTypes.string,
    accountstatus: PropTypes.string,
    amount_due: PropTypes.string,
    due_date: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
}

export { UsageGridCardComponent }
