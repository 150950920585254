import {
  faColumns,
  faChartLine,
  faHistory,
  faTruck,
  faMoneyBill,
  faEnvelope,
  faLock,
  faSignInAlt,
  faStore,
  faBuilding,
  faSchool,
  faWarehouse,
  faHospital,
  faUserAlt,
  faMapMarkedAlt,
  faCommentDots,
  faTrash,
  faPlay,
  faWallet,
  faDownload,
  faWater,
  faShower,
  faFaucet,
  faFireExtinguisher,
  faDungeon,
  faSearch,
  faPiggyBank,
  faCreditCard,
  faPhoneAlt,
  faComments,
  faAt,
  faRoad,
  faQuestion,
  faQuestionCircle,
  faBan,
  faUndoAlt,
  faHouse,
  faWaterLadder,
  faBuildingColumns,
  faShop,
} from '@fortawesome/free-solid-svg-icons'

import { faSprinkler, faArrowRight, faArrowLeft } from '@fortawesome/pro-light-svg-icons'

const ACCOUNT_DISPLAY_ICONS = {
  house: { file: faHouse, displayName: 'house' },
  store: { file: faStore, displayName: 'store' },
  building: { file: faBuilding, displayName: 'building' },
  school: { file: faSchool, displayName: 'school' },
  warehouse: { file: faWarehouse, displayName: 'warehouse' },
  hospital: { file: faHospital, displayName: 'hospital' },
  sprinkler: { file: faSprinkler, displayName: 'sprinkler' },
  water: { file: faWater, displayName: 'water' },
  waterLadder: { file: faWaterLadder, displayName: 'water-ladder' },
  shower: { file: faShower, displayName: 'shower' },
  faucet: { file: faFaucet, displayName: 'faucet' },
  fireExtinguisher: { file: faFireExtinguisher, displayName: 'fire extinguisher' },
  dungeon: { file: faDungeon, displayName: 'dungeon' },
  buildingColumns: { file: faBuildingColumns, displayName: 'building-columns' },
  truck: { file: faTruck, displayName: 'truck' },
  shop: { file: faShop, displayName: 'shop' },
}

// Need to refactor all icons to use ACCOUNT_DISPLAY_ICONS
const ACCOUNT_ICONS = {
  house: faHouse,
  store: faStore,
  building: faBuilding,
  school: faSchool,
  warehouse: faWarehouse,
  hospital: faHospital,
  sprinkler: faSprinkler,
  water: faWater,
  waterLadder: faWaterLadder,
  shower: faShower,
  faucet: faFaucet,
  fireExtinguisher: faFireExtinguisher,
  dungeon: faDungeon,
  buildingColumns: faBuildingColumns,
  truck: faTruck,
  shop: faShop,
}

const ICONS = {
  columns: faColumns,
  chart: faChartLine,
  history: faHistory,
  truck: faTruck,
  money: faMoneyBill,
  envelope: faEnvelope,
  signIn: faSignInAlt,
  lock: faLock,
  user: faUserAlt,
  map: faMapMarkedAlt,
  message: faCommentDots,
  trash: faTrash,
  arrow: faPlay,
  wallet: faWallet,
  arrowRight: faArrowRight,
  arrowLeft: faArrowLeft,
  exportFile: faDownload,
  search: faSearch,
  piggyBank: faPiggyBank,
  creditCard: faCreditCard,
  phoneAlt: faPhoneAlt,
  chat: faComments,
  at: faAt,
  road: faRoad,
  question: faQuestion,
  questionCircle: faQuestionCircle,
  ban: faBan,
  undo: faUndoAlt,
}

export { ICONS, ACCOUNT_ICONS, ACCOUNT_DISPLAY_ICONS }
