import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { IconComponent } from '@/components/common/IconComponent'
import { GridCardOverlayComponent } from '@/components/common/GridCardOverlayComponent'
import selectors from '@/redux/selectors'
import { useCommonApi } from '@/redux/ducks/commonDuck'
import { PropTypes } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { usePayments } from '@/hooks/usePayments.queries'
import { OBJECT_VARS } from '@/utils/models'
import { useAccountActivity } from './useAccountActivity.queries'
import { MoveOutAlert } from './alert/MoveOutAlert.component'

// ----------------- Styled Components ------------------ //

const Container = styled.div`
  position: relative;
  width: 384px;
  height: 142px;
  background: ${({ hasAlert, theme }) => (hasAlert ? theme.colors.lightGrey : '#ffffff')};
  & div,
  & span,
  & p {
    color: ${({ hasAlert, theme }) => (hasAlert ? theme.text : '#4f4f4f')};
  }
  cursor: pointer;
  border: 1px solid #3250b3;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(31, 32, 65, 0.3);
  border-radius: 4px;
  font-family: Roboto;
  padding-left: 18px;
  padding-top: 12px;
  padding-right: 13px;
  padding-bottom: 25px;
  margin-right: 20px;
  margin-bottom: 20px;
`

const AccountWrapper = styled.div`
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 5px;
`

const AccountTitle = styled.span`
  display: inline-block;
  font-weight: 500;
  margin-right: 5px;
`

const AccountValue = styled.span`
  font-weight: 100;
`

const AddressWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

const AddressTextWrapper = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-size: 22px;
  & p {
    margin-bottom: -8px;
  }
`

const MoveOutCardIcon = styled(IconComponent)`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 13px;
  margin-left: 0;
  color: ${({ theme, hasAlert }) => (hasAlert ? theme.text : theme.primary)};
`

const ClickableArea = styled.button`
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  text-align: left;
  &:focus {
    outline: none;
  }
`

const BalanceWrapper = styled.div`
  margin-bottom: 5px;
`

const MoveOutGridCard = ({ accountKey, accountNumber, iconName, address, nickname }) => {
  const { data, isLoading } = usePayments([accountKey])
  const status = useAccountActivity(accountKey)
  const dispatch = useDispatch()
  const icon = selectors.icon.getIconFromList(iconName)
  const selected = useSelector((state) => selectors.account.getIsAccountSelected(state, accountKey))
  const hasAlert = status.data ? !status.data.canMoveOut : null
  const [showAlert, setShowAlert] = React.useState(false)

  const handleSelect = () => {
    if (!status) return null
    if (hasAlert) {
      return setShowAlert(true)
    }
    if (status.data?.canMoveOut) {
      dispatch(useCommonApi.selectAccount(accountKey))
    }
    return null
  }

  return (
    <Container hasAlert={hasAlert}>
      <MoveOutAlert
        accountKey={accountKey}
        status={status}
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      <ClickableArea type="button" onClick={handleSelect}>
        {selected && <GridCardOverlayComponent />}
        <AccountWrapper hasAlert={hasAlert}>
          <AccountTitle>Account:</AccountTitle>
          <AccountValue>{nickname || accountNumber}</AccountValue>
        </AccountWrapper>
        <BalanceWrapper>
          <AccountTitle>Balance due:</AccountTitle>{' '}
          <AccountValue>
            ${isLoading ? <Skeleton width={50} /> : data[0][OBJECT_VARS.finalBalance]}
          </AccountValue>
        </BalanceWrapper>
        <AddressWrapper>
          <MoveOutCardIcon size="3em" icon={icon} />
          <AddressTextWrapper>
            <p>{address}</p>
            <p>San Antonio, TX 78209</p>
          </AddressTextWrapper>
        </AddressWrapper>
      </ClickableArea>
    </Container>
  )
}

MoveOutGridCard.propTypes = {
  accountKey: PropTypes.number.isRequired,
  accountNumber: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
}

export { MoveOutGridCard }
