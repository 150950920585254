import { loadFromLocalStorage } from '@//token'

// ----- USER ------ //
const BASE_URL = process.env.REACT_APP_API_BASE_URL
const CREATE_USER = `${BASE_URL}/identity-public-api/public/api/createuseridentity`
const LOGIN_USER = `${BASE_URL}/identity-public-api/public/api/login`
const GET_IDENTITY_BY_EMAIL = `${BASE_URL}/identity-public-api/public/api/getidbyemail?email=`
const GET_USER_IDENTITY = `${BASE_URL}/identity-public-api/public/api/getidentity`
const GET_IDENTITY_BY_USERNAME = `${BASE_URL}/identity-public-api/public/api/getidbyusername?userName=`
const RESET_USER_PASSWORD = `${BASE_URL}/identity-public-api/public/api/resetuserpassword`
const REFRESH_TOKEN = `${BASE_URL}/identity-public-api/public/api/refreshtoken`
const GET_SECRET_QUESTION = `${BASE_URL}/identity-public-api/public/api/getsecretquestion`

const UPDATE_USER_IDENTITY = `${BASE_URL}/identity-public-api/public/api/updateuseridentity`

// ------- ACCOUNT ------- //
const GET_USER_ACCOUNTS_BY_ID = `${BASE_URL}/account-public-api/public/api/getaccountsbyid?x52Id=`
const SAVE_ACCOUNT = `${BASE_URL}/account-public-api/public/api/saveaccount`
const VALIDATE_ACCOUNT = `${BASE_URL}/account-public-api/public/api/validateaccount`
const UNLINK_ACCOUNT = `${BASE_URL}/account-public-api/public/api/unlinkaccount`
const GET_BILLING_ADDRESS = `${BASE_URL}/account-public-api/public/api/getbillingaddress/`

// ---- WALLET ------ //
const GET_ROUTING_INFO = `${BASE_URL}/wallet-public-api/public/api/getroutinginfo/`

// ----- Bill History ----- //
const GET_BILLING_SUMMARY = `${BASE_URL}/billing-public-api/public/api/getbillingsummary`
const GET_BILL_LINE_ITEMS = `${BASE_URL}/billing-public-api/public/api/getbilllineitems`
const GET_BILL_COLLECTION = `${BASE_URL}/billing-public-api/public/api/getbillcollection`
export const GET_BILL_PDF = `${BASE_URL}/utilities-public-api/public/api/getbillingpdf`

// ---- Service Request  ---- //
const SUBMIT_SALESFORCE_SERVICE_REQUEST = `${BASE_URL}/servicerequest-public-api/public/api/salesforceservicerequest`
const GET_SERVICE_REQUEST_COUNT = `${BASE_URL}/servicerequest-public-api/public/api/getservicerequestcount`

// ---- Service Request  ---- //
const SUBMIT_SALESFORCE_CONTACT_US = `${BASE_URL}/utilities-public-api/public/api/addcontact`

const handleResponse = async (res) => {
  const json = await res.json()
  if (!json) {
    return json
  }
  const error = {
    statusText: res.statusText,
    statusCode: res.status,
    message: json,
    url: res.url,
  }
  return res.status === 200 ? json : Promise.reject(error)
}

const handleError = (res) => {
  const error = {
    statusText: res.statusText,
    statusCode: res.statusCode,
    message: res.message?.title,
    url: res.url,
  }

  return Promise.reject(error)
}

const getHeaders = (requiresInfor, additionalHeaders) => {
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':
      'http://localhost:3000,https://mycommercialqa.saws.org,https://business.saws.org,https://as-mycommercial-prod-sc.azurewebsites.net,https://sawsservices.saws.org',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY,
    Authorization: `Bearer ${loadFromLocalStorage('token')?.accessToken}`,
    InforUserName: requiresInfor && process.env.REACT_APP_INFOR_USERNAME,
    InforEncryptedPassword: requiresInfor && process.env.REACT_APP_INFOR_KEY,
    ...(additionalHeaders && additionalHeaders),
  }
}

export const fetchWrapper = (url, { body, method, mode, requiresInfor, additionalHeaders }) => {
  return fetch(url, {
    method,
    mode,
    headers: getHeaders(requiresInfor, additionalHeaders),
    body,
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

// ------------ Authentication ------------ //
const createUser = (req) => {
  return fetch(CREATE_USER, {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(),
    body: JSON.stringify(req),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

const login = (req) => {
  const url = LOGIN_USER
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(),
    body: JSON.stringify(req),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

const resetUserPassword = (req) => {
  const url = RESET_USER_PASSWORD
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ ...req, appid: process.env.REACT_APP_APP_ID }),
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

const updateUser = (req) => {
  const url = UPDATE_USER_IDENTITY
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ ...req, appId: process.env.REACT_APP_APP_ID }),
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const fetchRefreshToken = () => {
  const url = REFRESH_TOKEN
  const { refreshToken, accessToken } = loadFromLocalStorage('token') || {}
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      refreshToken: refreshToken.tokenString,
      accessToken,
      x52Id: refreshToken.x52Id,
    }),
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const getSecretQuestion = (username) => {
  const url = `${GET_SECRET_QUESTION}?userName=${username}`
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}
// ------------- Accounts ----------------- //
const fetchUserAccounts = (x52Id) => {
  const url = GET_USER_ACCOUNTS_BY_ID + x52Id
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

const saveAccount = (req) => {
  const url = SAVE_ACCOUNT
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(),
    body: JSON.stringify(req),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const getUserByEmail = (email) => {
  const url = GET_IDENTITY_BY_EMAIL + email
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const getUserByUsername = (username) => {
  const url = GET_IDENTITY_BY_USERNAME + username
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

const getUserIdentity = () => {
  const url = GET_USER_IDENTITY
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(true),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const validateAccount = (accountNumber, streetNumber) => {
  const url = `${VALIDATE_ACCOUNT}?acctNum=${accountNumber}&stNumber=${streetNumber}`
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const unlinkAccount = (userId, accountKey) => {
  const url = `${UNLINK_ACCOUNT}?x52Id=${userId}&accountKey=${accountKey}`
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const getBillingAddress = (accountKey) => {
  const url = GET_BILLING_ADDRESS + accountKey
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(true),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const validateRoutingNumber = (routingNum) => {
  const url = GET_ROUTING_INFO + routingNum
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(),
  })
}

// --------- Bill History --------- //
export const getBillSummary = (accountKey) => {
  const url = `${GET_BILLING_SUMMARY}/${accountKey}/`
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const getBillLineItems = (accountKey, billKey) => {
  const url = `${GET_BILL_LINE_ITEMS}/${accountKey}/${billKey}/`
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const getBillCollection = (accountKey, months) => {
  const url = `${GET_BILL_COLLECTION}/${accountKey}?months=60`
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(true),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const getBillPdf = (accountKey, billKey) => {
  const url = `${GET_BILL_PDF}?accountKey=${accountKey}&billingKey=${billKey}`
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(false, { responseType: 'blob' }),
  })
}

export const submitSalesforceContactUs = (req) => {
  const url = SUBMIT_SALESFORCE_CONTACT_US
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(true),
    body: JSON.stringify(req),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

export const submitServiceRequest = (req) => {
  const url = SUBMIT_SALESFORCE_SERVICE_REQUEST
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(true),
    body: JSON.stringify(req),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

const getServiceRequestCount = () => {
  const url = `${GET_SERVICE_REQUEST_COUNT}`
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(),
  })
    .then((res) => handleResponse(res))
    .catch((res) => handleError(res))
}

const API = {
  createUser,
  login,
  fetchUserAccounts,
  getUserByUsername,
  getUserIdentity,
  fetchRefreshToken,
  saveAccount,
  validateRoutingNumber,
  getBillSummary,
  getBillLineItems,
  getBillCollection,
  unlinkAccount,
  resetUserPassword,
  submitServiceRequest,
  getServiceRequestCount,
  updateUser,
  getBillingAddress,
  submitSalesforceContactUs,
}

export { API }
