import React from 'react'
import { Animated } from 'react-animated-css'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getSelectedAccountKeys } from '@/redux/ducks/commonDuck'
import { MoveOutNavButton } from '../common/MoveOutNavButton.component'
import { MoveOutPageWrapper } from '../common/MoveOutPageWrapper.component'
import { MoveOutGrid } from './MoveOutGrid.component'

export const MoveOutBasePage = ({ isDisabled, onContinue, resetAddressState }) => {
  const selected = useSelector(getSelectedAccountKeys)
  React.useEffect(() => {
    return () => resetAddressState()
  }, [])
  return (
    <>
      <MoveOutPageWrapper>
        <p>
          Click every account you’d like to manage with the same move-out action. Only accounts
          displaying a green icon are eligible for move out.
        </p>
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible>
          <MoveOutGrid />
        </Animated>
      </MoveOutPageWrapper>
      <MoveOutNavButton
        className="btn-myc-col"
        onClick={onContinue}
        disabled={isDisabled}
        buttonTex="selectaccount"
      >
        {isDisabled ? 'Select Accounts to Move' : `Continue | ${selected.length} selected`}
      </MoveOutNavButton>
    </>
  )
}

MoveOutBasePage.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  resetAddressState: PropTypes.func.isRequired,
}
