import { useQuery } from 'react-query'
import { fetchWrapper } from '@/redux/api'

const GET_PAYMENT_HISTORY_DATA = `${process.env.REACT_APP_API_BASE_URL}/payment-public-api/public/api/payhistory`

const fetchPaymentHistory = (key) => {
  const url = `${GET_PAYMENT_HISTORY_DATA}/${key}`
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: false,
  })
}
const usePaymentHistory = (key, options) => {
  return useQuery(['paymentHistory', key], () => fetchPaymentHistory(key), {
    ...options,
  })
}

export { usePaymentHistory }
